.custom-color-button{
    color: #212529;
    outline: 1px solid #212529;
    border: 3px solid #f8f9fa;
    border-radius: 100%;
    width: 2.3rem;
    height: 2.3rem;
    box-shadow: none;
}

.custom-color-button:focus, .custom-color-button:active{
    box-shadow: none !important;
}

.custom-color-button-active{
    outline: 3px solid #ff00005e !important;
}

.counter-input, .counter-input:focus{
    width: 50px;
    height: 50px;
    text-align: center; 
    background-color: #ffffff00 !important;
    border: none;
    box-shadow: none;
}

.counter-input-div{
    overflow: hidden;
    margin-right: 5px; 
}

.counter-button, .counter-button:hover, .counter-button:focus, .counter-button:active{
    color: #212529;
    height: 50px;
    width: 50px;
    background-color: #ffffff00;
    border: none;
    box-shadow: none;
}

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    appearance: none;
    margin: 0 !important;
}

.no-spin {
    appearance: none;
    -moz-appearance:textfield !important;
}

.input-disable-style{
    background-color: #ffffff00 !important;
    border: none !important;
    color: #212529 !important;
    caret-color: transparent;
    cursor: default;
}

.input-disable-style:focus{
    box-shadow: none;
}
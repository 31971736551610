.line-div {
  height: 25px;
  width: 100%;
  background-color: #e91e63; 
  top: 0;
  left: 0;
  z-index: 1; 
  text-align: center;
  font-weight: 600;
}

.nav-wrap{
  flex-wrap: wrap;
}

.nav-icons{
  font-size: 25px;
  color: #a7a7a7;
  transition: color .2s;
}

.nav-link{
  font-weight: 700;
}

.logo{
  max-height: 40px;
}

.invert-color{
  filter: invert(1);
}

.nav-link a:after,
.nav-link a:before {
  transition: all .5s;
}

.stroke ul li a {
  position: relative;
}
.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}
.stroke ul li a:hover:after {
  width: 100%;
}

.nav-hamb-button, .nav-hamb-button:focus{
  color: rgba(255, 255, 255, 0) !important;
  border: none;
}

.nav-icons:hover{
  transition: color .2s;
  color: #e91e63;
}

.dropdown-menu-custom{
  background-color: #212529;
}

.search-input, .search-input:focus-visible{
  color: rgb(194, 194, 194);
  background-color: #ffffff00;
  width: 40vw;
  border: none;
  outline: none;
}

.search-input-container{
  border-bottom: 1px solid rgb(194, 194, 194);
}

@media screen and (max-width: 991px) {
  #navbarSupportedContent {
    order: 2; /* Cambia el orden de div2 */
  }

  #icons-section {
    order: 1; /* Cambia el orden de div3 */
  }
}

@media screen and (max-width: 1050px) {
  #icons-section{
    padding: 0px !important;
    margin: 0px !important;
  }
}



.navbar-toggler span {
  display: block;
  background-color: #ffffff;
  height: 3px;
  width: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}



.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(2) {
  opacity: 1;
}

.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}

.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
  background-color: #e91e63;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
  background-color: #e91e63;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
  background-color: #e91e63;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}

.lblCartCount {
    font-size: 12px;
    background: #e91e63;
    color: #fff;
    padding: 2px 5px;
    vertical-align: top;
    margin-left: -16px; 
}
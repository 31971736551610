.showimg-div{
    max-width: 100%;
    align-items: center;
    justify-content: center;
}

.img-little{
    height: 50%;
    transition: 0.4s filter;
}

.img-big{
    height: 100%;
    max-height: 100%;
    transition: 0.4s filter;
}

.img-little:hover, .img-big:hover{
    filter: brightness(0.9);
    transition: 0.4s filter;
}
.formSub-text{
  font-weight: 650 !important;
  color: rgb(105, 105, 105);
}

.subscription-div{
  background-color: black;
}

.subscription-title{
  font-weight: 800;
}

.subscription-p{
  font-weight: 700;
}